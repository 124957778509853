<template>
  <div v-if="hasToShowLink">
    <a @click="userWantToSeeMoreInfo">Ver información adicional</a>
  </div>
  <v-img
    v-else-if="hasImage"
    class="product-image"
    v-bind="props"
    :src="hasImage ? currentProduct.secondaryImage : ''"
  ></v-img>
</template>

<script>
import getCurrentProduct from "@/mixins/getCurrentProduct";

export default {
  mixins: [getCurrentProduct],
  methods: {},
  data: () => {
    return {
      hasUserClickedInMoreInfo: false,
    };
  },
  methods: {
    userWantToSeeMoreInfo(e) {
      e.preventDefault();
      this.hasUserClickedInMoreInfo = true;
    },
  },
  computed: {
    hasToShowLink() {
      return this.hasImage && !this.hasUserClickedInMoreInfo;
    },
    hasImage() {
      if (this.currentProduct?.secondaryImage) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.product-image {
  /* max-height: 227px; */
  margin: 10px 0px;
  border-radius: 8px 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

div {
  margin-top: 32px;
  margin-left: 32px;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .product-image {
    border-radius: 0px 0px;
  }
}
</style>
